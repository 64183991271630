import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Das Thema Barrierefreiheit gewinnt immer mehr an Bedeutung. Der `}<a parentName="p" {...{
        "href": "https://ec.europa.eu/social/main.jsp?catId=1202"
      }}>{`European Accessibility Act`}</a>{`
schreibt ab 2025 verbindliche Anforderungen für Produkte und Dienstleistungen vor. Damit steigt auch der
Bedarf an Fachleuten, die Unternehmen bei der barrierefreien Gestaltung ihrer Websites und Apps unterstützen.`}</p>
    <p>{`Doch was zeichnet eine Barrierefreiheits-Expertin oder einen -Experten aus? Woher weiß ich, dass jemand tatsächlich Ahnung hat
oder nur mit Schlagwörtern um sich wirft?`}</p>
    <p>{`Aus meiner Sicht spielen `}<strong parentName="p">{`anerkannte Zertifizierungen`}</strong>{` hierbei eine wichtige Rolle. In diesem Artikel möchte ich auf die Vor- und
Nachteile von Accessibility-Zertifizierungen eingehen und eine Definition wagen, welche Skills man von Fachleuten erwarten kann.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAgT/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFyePY2iYD/AP/EABoQAQADAAMAAAAAAAAAAAAAAAIDERIBIjL/2gAIAQEAAQUCdZjOWPN9TJymGq//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwFn/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BJ//EABsQAAEEAwAAAAAAAAAAAAAAAAABEBEhMUFh/9oACAEBAAY/AhKitvPDJ//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFBgZH/2gAIAQEAAT8hvo+Q9dKuGOHsEU4OEzKmOq+z/9oADAMBAAIAAwAAABBcP//EABcRAAMBAAAAAAAAAAAAAAAAAAABIRH/2gAIAQMBAT8QajW0/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxAchy//xAAcEAEAAwACAwAAAAAAAAAAAAABABExIbFBYXH/2gAIAQEAAT8QK0E9i3nxANYtnImd9TdQIfstKnEZbOFOWnK7mju5Sf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Frau im Rollstuhl. Sie balanciert einen Laptop auf ihren Knien.",
          "title": "Eine Frau im Rollstuhl. Sie balanciert einen Laptop auf ihren Knien.",
          "src": "/static/ea4c2d3e22cf17ccecda03564bc0ae41/e5166/pexels-marcus-aurelius-woman-in-wheelchair.jpg",
          "srcSet": ["/static/ea4c2d3e22cf17ccecda03564bc0ae41/f93b5/pexels-marcus-aurelius-woman-in-wheelchair.jpg 300w", "/static/ea4c2d3e22cf17ccecda03564bc0ae41/b4294/pexels-marcus-aurelius-woman-in-wheelchair.jpg 600w", "/static/ea4c2d3e22cf17ccecda03564bc0ae41/e5166/pexels-marcus-aurelius-woman-in-wheelchair.jpg 1200w", "/static/ea4c2d3e22cf17ccecda03564bc0ae41/b17f8/pexels-marcus-aurelius-woman-in-wheelchair.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Marcus Aurelius / pexels.com`}</em></p>
    <p>{`Hinweis: Ich habe 2019 das Zertifikat `}<a parentName="p" {...{
        "href": "https://www.incite.at/de/programm/certified-webaccessibility-expert/"
      }}>{`„Certified Web Accessibility Expert"`}</a>{`
der UBIT-Akademie incite erworben. Seit Anfang 2023 bin ich „IAAP Certified Professional in Accessibility Core Competencies (CPACC)“
(`}<a parentName="p" {...{
        "href": "https://www.credly.com/badges/1839d107-b0d4-4788-ac7f-7d4905ed4f7e/linked_in_profile"
      }}>{`Nachweis bei Credly`}</a>{`).`}</p>
    <h2>{`Mindestanforderungen an Expert:innen`}</h2>
    <p>{`Barrierefreiheits-Expert:innen sollten `}<strong parentName="p">{`Empathie für Menschen mit Behinderung`}</strong>{` mitbringen. Sie sollten ein aufrichtiges Interesse
am Alltag dieser Menschen haben und versuchen, sich in ihre jeweilige Situation hineinzuversetzen:`}</p>
    <ul>
      <li parentName="ul">{`Wie surfen blinde Menschen mithilfe eines Screenreaders im Internet?`}</li>
      <li parentName="ul">{`Unter welchen Bedingungen können Personen mit motorischer Beeinträchtigung eine Website alleine mit der Tastatur bedienen?`}</li>
      <li parentName="ul">{`Wann sind Video- und Audioinhalte auch für gehörlose Menschen barrierefrei?`}</li>
      <li parentName="ul">{`usw.`}</li>
    </ul>
    <p>{`Eine Expertin oder Experte muss die `}<strong parentName="p">{`verschiedenen Nutzergruppen und assistiven Technologien`}</strong>{` kennen. Dazu zählen
`}<a parentName="p" {...{
        "href": "https://de.wikipedia.org/wiki/Softwarelupe#Bildschirmlupe"
      }}>{`Bildschirmlupen`}</a>{`,
`}<a parentName="p" {...{
        "href": "/de/forced-colors-mode.de/"
      }}>{`Hochkontrast-Modus`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=dEbl5jvLKGQ"
      }}>{`Screenreader`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/perspective-videos/voice/"
      }}>{`Spracheingabe-Software`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/perspective-videos/keyboard/"
      }}>{`Tastaturen`}</a>{`,
und viele mehr.`}</p>
    <p>{`Wissen über die relevanten `}<strong parentName="p">{`Normen und Gesetze`}</strong>{` sind eine weitere Mindestanforderung. Dazu zählen sowohl die Erfolgskriterien
der `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/standards-guidelines/wcag/"
      }}>{`Web Content Accessibility Guidelines (WCAG)`}</a>{`, der europäische Standard
`}<a parentName="p" {...{
        "href": "https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf"
      }}>{`EN 301 549`}</a>{` sowie relevante nationale
Gesetze wie etwa das österreichische `}<a parentName="p" {...{
        "href": "https://www.usp.gv.at/gesetzliche-neuerungen/Bundesgesetzblatt/barrierefreiheitsgesetz.html"
      }}>{`Barrierefreiheitsgesetz (BaFG)`}</a>{`.`}</p>
    <p>{`Darüber hinaus sollten Accessibility-Expert:innen auch Erfahrung im `}<strong parentName="p">{`Testen auf Barrierefreiheit`}</strong>{` mitbringen. Sie müssen beurteilen
können, ob eine Website bzw. App konkrete Kriterien einhält, und Lösungswege für gefundene Barrieren aufzeigen können.`}</p>
    <h2>{`Barrierefreiheits-Zertifizierungen`}</h2>
    <p>{`Verschiedene Institutionen bieten Zertifizierungen zum Thema Barrierefreiheit an. Es gibt nationale Zertifizierungsstellen wie etwa
die `}<a parentName="p" {...{
        "href": "https://www.incite.at/"
      }}>{`UBIT-Akademie incite`}</a>{` in Österreich. Diese bietet die Zertifizierung
zum `}<ItalicText mdxType="ItalicText">{`Certified WebAccessibility Expert`}</ItalicText>{` an.`}</p>
    <p>{`Die `}<a parentName="p" {...{
        "href": "https://www.accessibilityassociation.org"
      }}>{`International Association of Accessibility Professionals (IAAP)`}</a>{` bietet gleich
mehrere Zertifizierungen an, darunter etwa den `}<ItalicText mdxType="ItalicText">{`Certified Professional in Accessibility Core Competencies (CPACC)`}</ItalicText>{`
und den `}<ItalicText mdxType="ItalicText">{`Web Accessibility Specialist (WAS)`}</ItalicText>{`. Accessibility-Expert:innen sollen gezielt beim Aufbau
von Expertise unterstützt werden.`}</p>
    <p>{`Welche Vor- und Nachteile bieten solche Zertifizierungen?`}</p>
    <h3>{`Vorteile`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Erhöhte Glaubwürdigkeit`}</strong>{`: Eine Zertifizierung ist ein Nachweis, dass man über bestimmte Fähigkeiten und Erfahrungen verfügt.
Dies unterstreicht die Glaubwürdigkeit von Expert:innen.`}</li>
      <li parentName="ul"><strong parentName="li">{`Einheitlicher Standard`}</strong>{`: Um eine Zertifizierung zu erwerben, müssen Interessierte in Prüfungen bzw. Einzelgesprächen ihr
Wissen über konkrete Inhalte unter Beweis stellen. Seriöse Institutionen definieren hierfür einen öffentlich zugänglichen
`}<a parentName="li" {...{
          "href": "https://iaap-dach.org/files/Downloads/IAAP-DACH%20CPACC%20Syllabus.de.V105%20August%202023.pdf"
        }}>{`Syllabus`}</a>{`, der
diese Inhalte transparent macht.`}</li>
      <li parentName="ul"><strong parentName="li">{`Community für Austausch und Weiterbildung`}</strong>{`: Die IAAP unterstützt die Vernetzung von Accessibility-Expert:innen mit
Online-Foren, Webinaren und verschiedenen Veranstaltungen.`}</li>
    </ul>
    <h3>{`Nachteile`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Kosten`}</strong>{`: Eine Zertifizierung ist nicht gratis. Der CPACC kostet zum Beispiel knapp 500 US-Dollar (für Mitglieder etwas
weniger). Nicht jeder kann sich das leisten. Im Vergleich zu vielen anderen Zertifizierungen im IT-Bereich finde ich den
Preis jedoch sehr moderat.`}</li>
      <li parentName="ul"><strong parentName="li">{`Kognitive Barriere`}</strong>{`: Oft besteht eine Zertifizierung aus einem Multiple-Choice-Test mit unzähligen Fragen. Zum Beispiel
muss man beim CPACC ganze 100 Fragen in zwei Stunden beantworten. Das ist anstrengend. Für Menschen mit kognitiver Beeinträchtigung
kann das auch eine unüberwindbare Barriere darstellen.`}</li>
    </ul>
    <h2>{`Fazit`}</h2>
    <p>{`Aus meiner Sicht sind Barrierefreiheits-Zertifizierungen eine sinnvolle Sache. Sie stellen einen einheitlichen, transparenten
Nachweis für konkrete Fähigkeiten dar. Sie helfen Unternehmen dabei, echte Expertinnen von Schaumschlägern zu
unterscheiden.`}</p>
    <p>{`Müssen alle Accessibility-Expert:innen eine Zertifizierung machen? Natürlich nicht. Es gibt auch andere Mittel und Wege, seine
Expertise unter Beweis zu stellen. Etwa indem man schon viele Jahre barrierefreie Websites oder Apps gestaltet und diese als
Referenzen vorweisen kann.`}</p>
    <p>{`Ich bin jedenfalls froh über meine Zertifizierungen. Sie stellen zentrale Meilensteine in meiner Laufbahn als
Barrierefreiheits-Experte dar. Und das `}<a parentName="p" {...{
        "href": "https://www.credly.com/badges/1839d107-b0d4-4788-ac7f-7d4905ed4f7e/linked_in_profile"
      }}>{`erworbene Abzeichen`}</a>{`
sieht einfach cool aus! 😉`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://iaap-dach.org/iaap-dach-2/zertifizierungen.html"
        }}>{`Übersicht IAAP-Zertifizierungen`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.incite.at/de/programm/certified-webaccessibility-expert/"
        }}>{`Zertifizierung Certified WebAccessibility Expert (Österreich)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.edx.org/learn/web-accessibility/the-world-wide-web-consortium-w3c-introduction-to-web-accessibility"
        }}>{`W3C: Introduction to Web Accessibility`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      